<template>
    <div :class="{ 'flex items-center lg:block': !isOpen }"
        class="lg:hidden sticky top-header flex items-center bg-white z-10">
        <div class="w-full  cursor-pointer" @click="isOpen = !isOpen">
            <button class="flex items-center gap-1 py-3 lg:hidden px-2">
                <span class="text-xs font-semibold">Table of Contents</span>
                <Icon name="heroicons-outline:chevron-right" class="w-4 h-4 transition-transform duration-100 transform"
                    :class="[isOpen ? 'rotate-90' : 'rotate-0']" />
            </button>
            <DocsToc class="mb-4 overflow-y-auto max-h-[calc(100vh-5rem)]"
                :class="[isOpen ? 'lg:block' : 'hidden lg:block']" @move="isOpen = false" />
        </div>
    </div>
</template>

<script setup>
/* ToC mobile logic */
const isOpen = ref(false)
</script>
