<template>
    <div class="flex items-center gap-4 nav-socials">
        <!-- <a :href="socials?.discord" class="hidden lg:block discord-btn" target="_blank" aria-label="go to our discord">
            <Icon name="ic:baseline-discord"
                class="text-3xl text-primary-500 hover:text-primary-600 focus:text-[#3d51c5]" />
        </a> -->
        <a :href="socials?.github"
            class="flex items-center gap-2 bg-primary-500 text-white p-1 px-3 text-sm transition hover:bg-primary-600 star-count-container"
            target="_blank" aria-label="go to our github">

            <Icon name="mdi:github" class="text-xl focus:text-[#3d51c5]" />
            <span class="flex gap-1 items-center star-count">
                <span class="font-light">
                    <NavbarGithubStars />k
                </span>
                <Icon name="heroicons:star-20-solid" class="mb-[0.1rem]" />
            </span>
        </a>
    </div>
</template>

<script setup>
const docus = useDocus()
const socials = computed(() => docus.value?.socials || {})
</script>

<style scoped>
@media (max-width: 1315px) {
    .nav-socials {
        gap: 0.5rem;
    }
}

/* @media (max-width: 1150px) {
    .nav-socials {
        display: none;
    }
} */
@media (max-width: 950px) {
    .discord-btn {
        display: none;
    }
}

@media (max-width: 450px) {
    .nav-socials {
        display: none;
    }
}
</style>
